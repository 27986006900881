<template>
  <cui-acl  :roles="['HRsettings']" redirect>
    <h4>HR Instillinger | Ny standardkontrakt</h4>
    <div class="card">
      <div class="card-body">
        <!-- <h5 class="mb-4">
          <strong>Two Columns</strong>
        </h5> -->
        <a-form :form="form" layout="vertical" @submit="handleSubmit">
          <div class="row">
            <div class="col-md-4">
              <a-form-item label="Selskap">
                <a-select
                  v-decorator="[
                  'company',
                  {
                    initialValue: 'Skycall',
                    rules: [{ required: true, message: 'Venligst velg et selskap' }],
                  }
                  ]"
                  :options="companies"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Prosjekt">
                <a-select
                  v-decorator="[
                  'project',
                  {
                    rules: [{ required: true, message: 'Venligst velg et prosjekt' }],
                  }
                  ]"
                  :options="projects"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Avdelinger">
                <a-select
                  mode="multiple"
                  v-decorator="[
                  'department',
                  ]"
                  :options="departments"
                />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Tittel på kontrakt">
                <a-input
                  placeholder="Unik tittel for stillingen"
                  v-decorator="['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"
                />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Stillingstittel">
                <a-input
                  placeholder="Stillingstittel"
                  v-decorator="['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"
                />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Stillingstype (Fulltid/Deltid)">
                <a-select
                  v-decorator="[
                  'employmentType',
                  {
                    rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                  }
                  ]"
                  :options="[{value: 'full-time', label: 'Fulltid' },{value: 'part-time', label: 'Deltid' },{value: 'consultant', label: 'Konsulent' },]"
                />
              </a-form-item>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-2">
            </div>
            <div class="col-12">
              <a-form-item label="Kontraktsmal">
                <div class="clearfix">
                  <file-upload ref="fileUpload" @onUpload="handleFileUpload" :file-path="`contracts/'${form.getFieldValue('title')}.docx`"></file-upload>
                </div>
              </a-form-item>
            </div>
            <div class="col-12">
              <!-- CUSTOM FIELDS SECTION -->
            </div>
            <div class="col-xl-12 col-lg-12">
              <a-form-item label="Standard Roller">
                <user-roles
                  v-if="['userAdmin'].some(r => $store.state.user.roles.indexOf(r) >= 0)"
                  v-model="roles"
                />
                <p v-else>For sikkerhetsårsaker, så har du ikke muligheten til å definere standard roller.</p>
              </a-form-item>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="primary" class="btn btn-light px-5" @loading="loading">Lagre</button>
              </a-form-item>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import userRoles from '@/components/custom/user/roles/displayRoles'
import FileUpload from '@/components/custom/settings/fileUpload'

import { firestore } from '@/services/firebase'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'

export default {
  components: {
    cuiAcl,
    userRoles,
    FileUpload,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      companies: [],
      projects: [],
      departments: [],
      roles: [],
      fileList: [],
      loading: false,
    }
  },
  methods: {
    moment,
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    handleFileUpload(fileList) {
      this.fileList = fileList
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true
          const data = values
          let titleExists = 0
          await firestore.collection('settings').doc('contracts').collection('contracts').where('title', '==', data.title).get().then(snapshot => {
            titleExists = snapshot.docs.length
          })
          if (!titleExists) {
            data.defaultRoles = this.roles
            data.date = moment().format('YYYY-MM-DDTHH:mm')
            data.tripletex = {
              occupationCode: 6080,
            }
            if (this.fileList.length) {
              await this.$refs.fileUpload.uploadFile()
              data.docTemplate = await this.handleUpload(data.title.concat('.docx'))
            }
            console.log(data)
            firestore.collection('settings').doc('contracts').collection('contracts').add(data)
              .then(() => {
                openNotification('Kontrakt opprettet')
                this.$router.push({ path: '/hr/settings' })
              }).catch(err => {
                console.log(err)
                openNotification('Feil ved opprettelse av kontrakt, vennligst prøv igjen')
              })
          } else {
            openNotification('En stilling med samme navn finnes allerede.', 'Vennligst velg en ny en og prøv igjen.')
          }
          this.loaded = false
        }
      })
    },
  },
  mounted() {
    firestore.collection('settings').doc('companies').collection('companies').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const company = {
            label: doc.data().displayName,
            value: doc.id,
          }
          this.companies.push(company)
        })
        return firestore.collection('settings').doc('projects').collection('projects').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            label: doc.data().name,
            value: doc.id,
          }
          this.projects.push(project)
        })
        return firestore.collection('settings').doc('departments').collection('departments').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          const department = {
            label: doc.data().name,
            value: doc.id,
          }
          this.departments.push(department)
        })
      })
  },
}
</script>
