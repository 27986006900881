var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cui-acl',{attrs:{"roles":['HRsettings'],"redirect":""}},[_c('h4',[_vm._v("HR Instillinger | Ny standardkontrakt")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Selskap"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'company',
                {
                  initialValue: 'Skycall',
                  rules: [{ required: true, message: 'Venligst velg et selskap' }],
                }
                ]),expression:"[\n                'company',\n                {\n                  initialValue: 'Skycall',\n                  rules: [{ required: true, message: 'Venligst velg et selskap' }],\n                }\n                ]"}],attrs:{"options":_vm.companies}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Prosjekt"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'project',
                {
                  rules: [{ required: true, message: 'Venligst velg et prosjekt' }],
                }
                ]),expression:"[\n                'project',\n                {\n                  rules: [{ required: true, message: 'Venligst velg et prosjekt' }],\n                }\n                ]"}],attrs:{"options":_vm.projects}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Avdelinger"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'department' ]),expression:"[\n                'department',\n                ]"}],attrs:{"mode":"multiple","options":_vm.departments}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Tittel på kontrakt"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]),expression:"['title', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"}],attrs:{"placeholder":"Unik tittel for stillingen"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Stillingstittel"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]),expression:"['positionTitle', { rules:[{required: true, message: 'Kontrakten må ha en tittel'}]}]"}],attrs:{"placeholder":"Stillingstittel"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":"Stillingstype (Fulltid/Deltid)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'employmentType',
                {
                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],
                }
                ]),expression:"[\n                'employmentType',\n                {\n                  rules: [{ required: true, message: 'Venligst velg en stillingstype' }],\n                }\n                ]"}],attrs:{"options":[{value: 'full-time', label: 'Fulltid' },{value: 'part-time', label: 'Deltid' },{value: 'consultant', label: 'Konsulent' } ]}})],1)],1),_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-2"}),_c('div',{staticClass:"col-12"},[_c('a-form-item',{attrs:{"label":"Kontraktsmal"}},[_c('div',{staticClass:"clearfix"},[_c('file-upload',{ref:"fileUpload",attrs:{"file-path":("contracts/'" + (_vm.form.getFieldValue('title')) + ".docx")},on:{"onUpload":_vm.handleFileUpload}})],1)])],1),_c('div',{staticClass:"col-12"}),_c('div',{staticClass:"col-xl-12 col-lg-12"},[_c('a-form-item',{attrs:{"label":"Standard Roller"}},[(['userAdmin'].some(function (r) { return _vm.$store.state.user.roles.indexOf(r) >= 0; }))?_c('user-roles',{model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}):_c('p',[_vm._v("For sikkerhetsårsaker, så har du ikke muligheten til å definere standard roller.")])],1)],1),_c('div',{staticClass:"col-12"},[_c('a-form-item',[_c('button',{staticClass:"btn btn-light px-5",attrs:{"type":"primary"},on:{"loading":_vm.loading}},[_vm._v("Lagre")])])],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }