<!-- FileUpload.vue -->
<template>
  <div class="clearfix">
    <a-upload :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
      <a-button> <a-icon type="upload" /> Select File </a-button>
    </a-upload>
  </div>
</template>

<script>
import { storage } from '@/services/firebase' // Import storage from your Firebase configuration
import { openNotification } from '@/services/powerAutomate' // Import openNotification from your powerAutomate service

export default {
  props: {
    filePath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  mounted() {
    this.downloadFile()
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
      storage.ref(this.filePath).delete().then(() => {
        console.log('file deleted')
        openNotification('success', 'File deleted successfully')
      }).catch(err => {
        console.log(err)
        throw new Error('could not delete file')
      })
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    async uploadFile() {
      const file = this.fileList[0]
      let uploadedFile = {}
      try {
        await storage.ref(this.filePath).put(file).then((snapshot) => {
          console.log(snapshot)
          uploadedFile = {
            fullPath: snapshot.metadata.fullPath,
            name: snapshot.metadata.name,
            timeCreated: snapshot.metadata.timeCreated,
          }
          return snapshot.ref.getDownloadURL()
        }).then(url => {
          uploadedFile.url = url
          return uploadedFile
        }).catch(err => {
          console.log(err)
          throw new Error('could not uplaod file')
        })
        this.$emit('on-upload', this.fileList)
      } catch (error) {
        console.error('Error:', error)
        return error
        // You can emit an event or use a prop callback to handle the error in the parent component
      }
    },
    // You can use this method to download the file from the storage
    async downloadFile() {
      try {
        // get file info from the storage
        const file = await storage.ref(this.filePath).getMetadata()
        const url = await storage.ref(this.filePath).getDownloadURL()
        console.log(file)
        this.fileList = [{
          uid: '-1',
          name: file.name,
          status: 'done',
          url,
        }]
      } catch (error) {
        console.error('Error:', error)
        // You can emit an event or use a prop callback to handle the error in the parent component
      }
    },
  },
}
</script>
